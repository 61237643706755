@use "../../theme/variables.module.scss" as *;

.page-loader {
  display: flex;
  height: $device-view-height;
  justify-content: center;
  align-items: center;
  &__spinner.tecma-spinner.circle {
    border: solid $accent;
    border-left-color: $on-accent;
  }
}
