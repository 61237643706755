@use './theme/variables.module.scss' as *;

* {
  box-sizing: border-box;
}

body,
html {
  margin: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app-background {
  flex-direction: column;
  min-height: $device-view-height;
  background-color: $secondary;
  background-attachment: fixed;
  z-index: 1;
}

.app-footer {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  background: linear-gradient(0deg, $black, transparent);

  @media screen and (min-width: $BREAKPOINT_DESKTOP_M) {
    display: flex;
    justify-content: flex-end;
  }
}

.card {
  padding: 0;
  margin: auto;
  min-height: $device-view-height;
  width: 100%;
  gap: $spacing-l;
  border-radius: 0;
  z-index: 1;
  @media screen and (min-width: $BREAKPOINT_TABLET) {
    margin: 2rem auto;
    width: 32rem;
    min-height: 43.75rem;
    border-radius: $border-radius-external;
  }
  &-subtitle {
    font-size: 1.125rem;
    font-weight: 700;
    color: $on-general-sub;
    text-align: center;
    font-family: Lato, sans-serif;
    font-style: normal;
    line-height: 1.5rem;
    margin: 0;
  }
  &-description {
    color: $on-general-sub;
    text-align: center;
    font-family: Lato, sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
    margin: $spacing-none;
  }
  &-content {
    display: flex;
    padding: $spacing-none $spacing-m;
    flex-direction: column;
    align-items: center;
    gap: $spacing-m;
    flex: 1;
    width: 100%;
    margin: 0 auto;
    max-width: 23.75rem;
    @media screen and (min-width: $BREAKPOINT_TABLET) {
      max-width: 22rem;
      padding: 0;
    }
    .tecma-alert {
      min-width: 22rem;
    }
    form {
      gap: $spacing-s;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
    }
    &__input {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.5rem;
      align-self: stretch;
      .help-text-errored {
        margin-top: 0;
      }
      .input-label,
      .tecma-select-label {
        font-family: Lato, sans-serif;
        margin-bottom: $spacing-none;
        color: $on-general;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.5rem;
      }
      input {
        text-overflow: ellipsis;
      }
      &.phone-input {
        .tecma-select,
        .tecma-input,
        .tecma-select-box {
          width: 100%;
        }
      }
      &-checkbox {
        &__content {
          display: flex;
          > label {
            margin-left: 0;
          }
          .tecma-custom-checkbox span {
            padding: 0;
          }

          * {
            display: inline;
            font-family: Lato, sans-serif;
            color: $on-general;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.5rem;
          }
        }
      }
    }
    &__link {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      align-self: stretch;
      color: $accent;
      font-family: Lato;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.25rem;
      margin-bottom: $spacing-xs;
    }
  }
  &-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $spacing-xs;
    padding: $spacing-s $spacing-m;
    #language-selector-button.outlined.tecma-button {
      @media screen and (min-width: $BREAKPOINT_DESKTOP_M) {
        display: none;
      }
      color: $on-general;
      box-shadow: unset;
      &:hover {
        svg path {
          fill: $on-general;
        }
      }
      svg path {
        fill: $on-general;
      }
    }
    &__link {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      align-self: stretch;
      color: $accent;
      font-family: Lato;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.25rem;
      justify-content: center;
      padding: 1rem;
    }
  }
}
